import { Observable } from 'rxjs';

import { ContentType } from '../models/content-type';

/** Download service. */
export abstract class DownloadService {

	/**
	 * Downloads file from provided url.
	 * @param url Url to file.
	 * @param contentType Content type.
	 */
	public abstract downloadFile(url: string, contentType: ContentType): Observable<void>;
}
