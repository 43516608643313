import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UserMapper } from '../mappers/user.mapper';
import { userDtoSchema } from '../dtos/user.dto';
import { User } from '../models/user';
import { ConnectedAccountLink } from '../models/connected-account-link';
import { ConnectedAccountLinkMapper } from '../mappers/connected-acount-link.mapper';
import { ConnectedAccountLinkDto } from '../dtos/connected-account-link.dto';
import { AppPlatform } from '../models/platform';

import { AppUrlsConfig } from './app-urls.config';

/** Performs CRUD operations for users. */
@Injectable({
	providedIn: 'root',
})
export class UserApiService {

	private readonly apiUrls = inject(AppUrlsConfig);

	private readonly httpClient = inject(HttpClient);

	private readonly userMapper = inject(UserMapper);

	private readonly connectedAccountLinkMapper = inject(ConnectedAccountLinkMapper);

	/** Returns current user info.*/
	public getCurrentUser(): Observable<User> {
		return this.httpClient.get<unknown>(
			this.apiUrls.user.currentProfile,
		)
			.pipe(
				map(response => userDtoSchema.parse(response)),
				map(profileDto => this.userMapper.fromDto(profileDto)),
			);
	}

	/** Get stripe dashboard url. */
	public getStripeDashboardUrl(): Observable<ConnectedAccountLink> {
		return this.httpClient.post<ConnectedAccountLinkDto>(
			this.apiUrls.user.createConnectedAccount,
			{ platform: AppPlatform.getFromCapacitor() },
		).pipe(
			map(response => this.connectedAccountLinkMapper.fromDto(response)),
		);
	}
}
