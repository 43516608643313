import { Injectable } from '@angular/core';

import { ConfirmUserDeletionData } from '../models/confirm-user-deletion-data';
import { ConfirmUserDeletionDataDto } from '../dtos/confirm-user-deletion-data.dto';
import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { EntityValidationErrors } from '../models/app-error';

import { MapperToDto, ValidationErrorMapper } from './mappers';
import { extractErrorMessageByField } from './extract-error-message';

/** Confirm user deletion data mapper. */
@Injectable({ providedIn: 'root' })
export class ConfirmUserDeletionDataMapper implements
MapperToDto<ConfirmUserDeletionDataDto, ConfirmUserDeletionData>,
ValidationErrorMapper<ConfirmUserDeletionDataDto, ConfirmUserDeletionData> {
	/** @inheritdoc */
	public toDto(model: ConfirmUserDeletionData): ConfirmUserDeletionDataDto {
		return {
			otp_code: model.confirmationCode,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<ConfirmUserDeletionDataDto>,
	): EntityValidationErrors<ConfirmUserDeletionData> {
		return {
			confirmationCode: extractErrorMessageByField(errorDto, 'otp_code'),
			nonFieldErrors: extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}
}
