import { Injectable, inject } from '@angular/core';

import { AuthenticationStepDto } from '../dtos/auth/authentication-step.dto';
import { AuthenticationStep } from '../models/auth/authentication-step';

import { MapperFromDto } from './mappers';
import { TwoFactorAuthMethodMapper } from './two-factor-method.mapper';

/** Authentication step mapper. */
@Injectable({ providedIn: 'root' })
export class AuthenticationStepMapper implements MapperFromDto<AuthenticationStepDto, AuthenticationStep> {

	private readonly twoFactorMethodMapper = inject(TwoFactorAuthMethodMapper);

	/** @inheritDoc */
	public fromDto(dto: AuthenticationStepDto): AuthenticationStep {
		return {
			twoFactorMethod: dto.two_factor_type === '' ?
				null :
				this.twoFactorMethodMapper.fromDto(dto.two_factor_type),
			accessToken: dto.token,
			isTwoFactorRequired: dto.is_2fa_required,
		};
	}
}
