import { z } from 'zod';

import { twoFactorMethodSchema } from '../two-factor/two-factor-method.dto';

/** Validation schema for AuthenticationStepDto model. */
export const authenticationStepDtoSchema = z.object({
	/** 2FA method. If it's empty string, it means 2FA is not configured.  */
	two_factor_type: twoFactorMethodSchema.or(z.literal('')),

	/** Token expiration date. UTC format. */
	expiry: z.string(),

	/** Temporary token to proceed or configure 2FA. */
	token: z.string(),

	/**
	 * Whether two factor authorization is required for the user or not.
	 * Now, it is not necessary only for new users a certain period of time.
	 */
	is_2fa_required: z.boolean(),
});

/** Server response to user login. */
export type AuthenticationStepDto = z.infer<typeof authenticationStepDtoSchema>;
