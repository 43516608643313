import { Injectable, inject } from '@angular/core';

import { RegistrationDto } from '../dtos/registration.dto';
import { Registration } from '../models/registration';
import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { EntityValidationErrors } from '../models/app-error';

import { MapperToDto, ValidationErrorMapper } from './mappers';
import { extractErrorMessageByField } from './extract-error-message';
import { DateMapper } from './date.mapper';
import { PhoneNumberMapper } from './phone-number.mapper';

/** Registration mapper. */
@Injectable({ providedIn: 'root' })
export class RegistrationMapper implements
	MapperToDto<RegistrationDto, Registration>,
	ValidationErrorMapper<RegistrationDto, Registration> {

	private readonly dateMapper = inject(DateMapper);

	private readonly phoneNumberMapper = inject(PhoneNumberMapper);

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<RegistrationDto>): EntityValidationErrors<Registration> {
		return {
			email: extractErrorMessageByField(errorDto, 'email'),
			password: extractErrorMessageByField(errorDto, 'password'),
			passwordConfirm: extractErrorMessageByField(errorDto, 'password_confirm'),
			firstName: extractErrorMessageByField(errorDto, 'first_name'),
			lastName: extractErrorMessageByField(errorDto, 'last_name'),
			preferredName: extractErrorMessageByField(errorDto, 'preferred_name'),
			initials: extractErrorMessageByField(errorDto, 'initials'),
			entityName: extractErrorMessageByField(errorDto, 'entity'),
			username: extractErrorMessageByField(errorDto, 'username'),
			pronouns: extractErrorMessageByField(errorDto, 'pronoun'),
			clinicianType: extractErrorMessageByField(errorDto, 'clinician_type'),
			specialty: extractErrorMessageByField(errorDto, 'specialty'),
			npiNumber: extractErrorMessageByField(errorDto, 'npi_number'),
			graduationDate: extractErrorMessageByField(errorDto, 'graduation_date'),
			practiceState: extractErrorMessageByField(errorDto, 'primary_region_practice_state'),
			practiceZipCode: extractErrorMessageByField(errorDto, 'primary_region_practice_zip'),
			secondaryEmail: extractErrorMessageByField(errorDto, 'secondary_email'),
			phoneNumber: extractErrorMessageByField(errorDto, 'phone_number'),
			address: extractErrorMessageByField(errorDto, 'address'),
			addressState: extractErrorMessageByField(errorDto, 'address_state'),
			addressZipCode: extractErrorMessageByField(errorDto, 'address_zip'),
			faxNumber: extractErrorMessageByField(errorDto, 'fax_number'),
			signature: extractErrorMessageByField(errorDto, 'signature'),
		};
	}

	/** @inheritdoc */
	public toDto(model: Registration): RegistrationDto {
		return {
			address: model.address ?? undefined,
			address_state: model.addressState,
			address_zip: model.addressZipCode,
			clinician_type: model.clinicianType,
			email: model.email,
			entity: model.entityName,
			fax_number: model.faxNumber !== null ? this.phoneNumberMapper.toDto(model.faxNumber) : '',
			first_name: model.firstName,
			last_name: model.lastName,
			preferred_name: model.preferredName,
			initials: model.initials,
			credentials: model.credentials,
			npi_number: model.npiNumber,
			password: model.password,
			password_confirm: model.passwordConfirm,
			phone_number: this.phoneNumberMapper.toDto(model.phoneNumber),
			pronoun: model.pronouns,
			role: model.isStudent ? 'student' : 'clinician',
			secondary_email: model.secondaryEmail,
			specialty: model.specialty,
			primary_region_practice_state: model.practiceState,
			primary_region_practice_zip: model.practiceZipCode,
			username: model.username,
			graduation_date: model.graduationDate ? this.dateMapper.toDateOnlyDto(model.graduationDate) : null,
			signature: model.signature,
			allow_notifications: model.allowNotifications,
		};
	}
}
