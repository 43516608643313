import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../models/app-error';
import { PasswordReset } from '../models/password-reset';

import { PasswordResetDto } from '../dtos/password-reset.dto';
import { ValidationErrorDto } from '../dtos/validation-error.dto';

import { extractErrorMessageByField } from './extract-error-message';
import { MapperToDto, ValidationErrorMapper } from './mappers';

/** Mapper for reset password data. */
@Injectable({ providedIn: 'root' })
export class ResetPasswordMapper
implements
		MapperToDto<PasswordReset.Data, PasswordResetDto.Data>,
		ValidationErrorMapper<PasswordReset.Data, PasswordResetDto.Data> {
	/** @inheritdoc */
	public toDto(model: PasswordReset.Data): PasswordResetDto.Data {
		return {
			information: model.information,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<PasswordReset.Data>,
	): EntityValidationErrors<PasswordResetDto.Data> {
		return {
			information:
				extractErrorMessageByField(errorDto, 'information') ??
				extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}
}
