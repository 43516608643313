import { Injectable } from '@angular/core';

import { ConnectedAccountLinkDto } from '../dtos/connected-account-link.dto';
import { ConnectedAccountLink } from '../models/connected-account-link';

import { MapperFromDto } from './mappers';

/** Connect account link mapper. */
@Injectable({ providedIn: 'root' })
export class ConnectedAccountLinkMapper implements MapperFromDto<ConnectedAccountLinkDto, ConnectedAccountLink> {

	/** @inheritdoc */
	public fromDto(dto: ConnectedAccountLinkDto): ConnectedAccountLink {
		return {
			url: dto.url,
		};
	}

}
