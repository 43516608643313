import { Capacitor } from '@capacitor/core';
import { z } from 'zod';

/** Available app's platform schema. */
export const appPlatformSchema = z.enum(['web', 'ios', 'android']);

/** Available app's platform. */
export type AppPlatform = z.infer<typeof appPlatformSchema>;

export namespace AppPlatform {

	/** Available platform values. */
	export const ENUM = appPlatformSchema.Enum;

	/** Get app's platform from capacitor. */
	export function getFromCapacitor(): AppPlatform {
		const platform = Capacitor.getPlatform();

		if (platform === 'android') {
			return ENUM.android;
		} else if (platform === 'ios') {
			return ENUM.ios;
		}

		return ENUM.web;
	}

	/**
		* Execute function by current platform.
		* @param data Data.
		*/
	export function executeByPlatform<T>(data: Readonly<Record<AppPlatform, () => T>>): T {
		const platform = getFromCapacitor();

		return data[platform]();
	}
}
