import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
	catchError,
	concat,
	first,
	ignoreElements,
	map,
	merge,
	Observable,
	of,
	OperatorFunction,
	pipe,
	repeat,
	shareReplay,
	Subject,
	switchMap,
	take,
	tap,
	throwError,
} from 'rxjs';

import { AppErrorMapper } from '../mappers/app-error.mapper';
import { UserMapper } from '../mappers/user.mapper';
import { Login } from '../models/login';
import { PasswordReset } from '../models/password-reset';
import { User, UserUpdateData } from '../models/user';
import { Registration } from '../models/registration';
import { SessionRatesUpdate } from '../models/session-rate';
import { SessionRateMapper } from '../mappers/session-rate.mapper';
import { ConnectedAccountLink } from '../models/connected-account-link';
import { ConfirmUserDeletionDataMapper } from '../mappers/confirm-user-deletion-data.mapper';
import { ConfirmUserDeletionData } from '../models/confirm-user-deletion-data';
import { UserSecret } from '../models/user-secret';
import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { extractErrorMessageByField } from '../mappers/extract-error-message';
import { filterNull } from '../utils/rxjs/filter-null';
import { ContentType } from '../models/content-type';
import { AppError } from '../models/app-error';
import { AuthenticationStep } from '../models/auth/authentication-step';

import { AppUrlsConfig } from './app-urls.config';
import { AuthApiService } from './auth-api.service';
import { UserApiService } from './user-api.service';
import { UserSecretStorageService } from './user-secret-storage.service';
import { DownloadService } from './download.service';

/**
 * Stateful service for storing/managing information about the current user.
 */
@Injectable({
	providedIn: 'root',
})
export class UserService {
	/** Current user. `null` when a user is not logged in. */
	public readonly currentUser$: Observable<User | null>;

	/** Whether the current user is authorized. */
	public readonly isAuthorized$: Observable<boolean>;

	private readonly authService = inject(AuthApiService);

	private readonly userSecretStorage = inject(UserSecretStorageService);

	private readonly userApiService = inject(UserApiService);

	private readonly userMapper = inject(UserMapper);

	private readonly httpClient = inject(HttpClient);

	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly sessionRateMapper = inject(SessionRateMapper);

	private readonly confirmUserDeletionDataMapper = inject(ConfirmUserDeletionDataMapper);

	private readonly _userProfileUpdated$ = new Subject<void>();

	private readonly downloadService = inject(DownloadService);

	/** Whether current user have BAA file. */
	public readonly isCurrentUserHaveBaaFile$: Observable<boolean>;

	/** User profile updated. */
	public readonly userProfileUpdated$ = this._userProfileUpdated$.asObservable();

	public constructor() {
		this.currentUser$ = this.initCurrentUserStream();
		this.isAuthorized$ = this.currentUser$.pipe(map(user => user != null));
		this.isCurrentUserHaveBaaFile$ = this.currentUser$.pipe(
			map(user => user?.baaFile != null),
		);
	}

	/** Request confirmation code to delete profile. */
	public requestDeletionCode(): Observable<void> {
		return this.httpClient.get<void>(this.appUrlsConfig.user.deletionCode);
	}

	/**
		* Delete user's account.
		* @param data Confirm user deletion data.
		*/
	public deleteAccount(data: ConfirmUserDeletionData): Observable<void> {
		return this.httpClient.post<void>(
			this.appUrlsConfig.user.confirmDeletion,
			this.confirmUserDeletionDataMapper.toDto(data),
		).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.confirmUserDeletionDataMapper),
		);
	}

	/**
		* Update user profile.
		* @param data User profile update data.
		*/
	public updateUserProfile(data: UserUpdateData): Observable<void> {
		return this.httpClient.patch<unknown>(
			this.appUrlsConfig.user.currentProfile,
			this.userMapper.toUpdateDataDto(data),
		).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.userMapper),
			tap(() => this.markUserProfileUpdated()),
			map(() => undefined),
		);
	}

	/**
		* Update consultation dates.
		* @param data Consultation rates edit data.
		*/
	public updateConsultationRates(data: SessionRatesUpdate): Observable<void> {
		return this.httpClient.patch<unknown>(
			this.appUrlsConfig.user.currentProfile,
			this.sessionRateMapper.toUpdateDto(data),
		).pipe(
			tap(() => this.markUserProfileUpdated()),
			map(() => undefined),
		);
	}

	/**
	 * Create a user and authorize it.
	 * @param registrationData Registration data.
	 */
	public registerAndAuthorize(registrationData: Registration): Observable<void> {
		return concat(
			this.authService.register(registrationData),
			this.login({ username: registrationData.email, password: registrationData.password }).pipe(
				map(({ accessToken }) => ({ token: accessToken })),
				this.saveSecretAndWaitForAuthorized(),
			),
		);
	}

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<AuthenticationStep> {
		return this.authService.login(loginData);
	}

	/** Attempts to refresh user secret, in case it is not possible logs out current user. */
	public refreshSecret(): Observable<void> {
		const refreshSecretIfPresent$ = this.userSecretStorage.currentSecret$.pipe(
			first(),
			switchMap(secret => {
				if (secret != null) {
					return this.authService.refreshSecret(secret);
				}
				throw new AppError('Unauthorized');
			}),
			switchMap(newSecret => this.userSecretStorage.saveSecret(newSecret)),
		);
		return refreshSecretIfPresent$.pipe(
			catchError((error: unknown) =>
				concat(
					this.logout().pipe(ignoreElements()),
					throwError(() => error),
				)),
			map(() => undefined),
		);
	}

	/**
	 * Logout current user.
	 */
	public logout(): Observable<void> {
		return this.userSecretStorage.removeSecret();
	}

	/**
	 * Requests to reset the password.
	 * @param data Data for resetting the password.
	 * @returns Message for the user.
	 */
	public resetPassword(data: PasswordReset.Data): Observable<string> {
		return this.authService.resetPassword(data);
	}

	/**
	 * Set new password and confirm resetting.
	 * @param data Confirm password reset.
	 * @returns Success message.
	 */
	public confirmPasswordReset(data: PasswordReset.Confirmation): Observable<string> {
		return this.authService.confirmPasswordReset(data);
	}

	/** Marks user profile as updated. */
	public markUserProfileUpdated(): void {
		this._userProfileUpdated$.next();
	}

	/** Get stripe dashboard url. */
	public getStripeDashboardUrl(): Observable<ConnectedAccountLink> {
		return this.userApiService.getStripeDashboardUrl();
	}

	/**
	 * Confirm email address.
	 * @param code Confirmation code.
		* @param isSecondaryEmail Whether user tries to confirm the secondary email or not.
	 */
	public confirmEmail(code: string, isSecondaryEmail = false): Observable<void> {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const url = isSecondaryEmail ?
			this.appUrlsConfig.user.confirmSecondaryEmail :
			this.appUrlsConfig.user.confirmEmail;

		return this.httpClient.post<void>(url, { otp_code: code }).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
				// eslint-disable-next-line @typescript-eslint/naming-convention
				(errorDto: ValidationErrorDto<{otp_code: string;}>) => ({
					code: extractErrorMessageByField(errorDto, 'otp_code'),
				}),
			),
			tap(() => this.markUserProfileUpdated()),
		);
	}

	/**
	 * Send confirmation code to the current email address.
	 * @param isSecondaryEmail Whether user tries to confirm the secondary email or not.
	 */
	public sendConfirmationCode(isSecondaryEmail = false): Observable<void> {
		const url = isSecondaryEmail ?
			this.appUrlsConfig.user.sendSecondaryEmailConfirmationCode :
			this.appUrlsConfig.user.sendEmailConfirmationCode;
		return this.httpClient.get<void>(url);
	}

	/**
	 * Update current user email.
	 * @param email New email.
	 */
	public updateEmail(email: string): Observable<void> {
		return this.httpClient.patch<void>(this.appUrlsConfig.user.updateEmail, { email });
	}

	/**
	 * Update current user's secondary email.
	 * @param email New secondary email.
	 */
	public updateSecondaryEmail(email: string): Observable<void> {
		return this.httpClient.patch<void>(
			this.appUrlsConfig.user.updateSecondaryEmail,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ secondary_email: email },
		).pipe(
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
				// eslint-disable-next-line @typescript-eslint/naming-convention
				(errorDto: ValidationErrorDto<{secondary_email: string;}>) => ({
					email: extractErrorMessageByField(errorDto, 'secondary_email'),
				}),
			),
		);
	}

	/**
		* Set pages availability for the current user.
		* @param isAvailable Whether user is available for the pages or not.
		*/
	public setPagesAvailability(isAvailable: boolean): Observable<void> {
		return this.httpClient.patch<void>(
			this.appUrlsConfig.user.currentProfile,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ available_for_page: isAvailable },
		).pipe(
			tap(() => this.markUserProfileUpdated()),
		);
	}

	/** Save secret and wait for authorized operator. */
	public saveSecretAndWaitForAuthorized(): OperatorFunction<UserSecret, void> {
		return pipe(
			switchMap(secret => {
				const saveUserSecretSideEffect$ = this.userSecretStorage.saveSecret(secret).pipe(ignoreElements());

				return merge(
					this.isAuthorized$,
					saveUserSecretSideEffect$,
				);
			}),
			first(isAuthorized => isAuthorized),
			map(() => undefined),
		);
	}

	/** Downloads BAA. */
	public downloadBaa(): Observable<void> {
		return this.currentUser$.pipe(
			map(user => user?.baaFile),
			filterNull(),
			take(1),
			switchMap(baaFileUrl => this.downloadService.downloadFile(baaFileUrl, ContentType.PDF)),
			map(() => undefined),
		);
	}

	private initCurrentUserStream(): Observable<User | null> {
		return this.userSecretStorage.currentSecret$.pipe(
			switchMap(secret => (secret ?
				this.userApiService.getCurrentUser().pipe(repeat({ delay: () => this._userProfileUpdated$ })) :
				of(null))),
			shareReplay({ bufferSize: 1, refCount: false }),
		);
	}
}
